import React from "react";
import { Box, Stack } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  useGetTotalAndTickUsersQuery,
  useGetUserCountQuery,
} from "generated/graphql";
import { isEmpty } from "lodash-es";
import PieChart from "./PieChart";

interface CardProps {
  title: string;
}

const UserChartCard: React.FC<CardProps> = ({ title }) => {
  const { data } = useGetTotalAndTickUsersQuery();

  const seriesVerifiedUsers = [
    Number(useGetUserCountQuery()?.data?.getUserCount?.bluetick),
    Number(useGetUserCountQuery()?.data?.getUserCount?.greentick),
  ];

  const VerifiedUsersColor = ["#0099ff", "#00e673"];
  return (
    <Box
      sx={{
        "@media (max-width:320px)": {
          maxWidth: "288px",
          width: "100%",
        },
      }}
    >
      <Card
        sx={{
          maxWidth: "600px",
          minWidth: "450px",
          width: "100%",
          height: "400px",
          "@media (max-width:1600px)": {
            maxWidth: 500,
            minWidth: "0px",
          },
          "@media (max-width:600px)": {
            minWidth: 200,
          },
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            pt={2}
            pl={1.1}
            sx={{
              "@media (max-width:1600px)": {},
            }}
          >
            {title}
            <span
              style={{
                fontSize: "0.7rem",
                fontStyle: "normal",
                fontWeight: "lighter",
              }}
            >
              {" "}
              (Currently)
            </span>
          </Typography>

          <PieChart
            series={seriesVerifiedUsers}
            colors={VerifiedUsersColor}
            title={""}
            lables={["Blue Tick", "Green Tick"]}
          />

          <Stack direction="row" sx={{}} justifyContent="center">
            <Typography
              sx={{
                padding: "10px",
                "@media (max-width:1600px)": {
                  fontSize: "0.8rem",
                  padding: "7px",
                },
              }}
            >
              Total Users :{" "}
              {!isEmpty(data) ? (
                <strong>{Number(data?.getTotalAndTickUsers?.allUsers)}</strong>
              ) : (
                0
              )}
            </Typography>

            <Typography
              sx={{
                padding: "10px",
                "@media (max-width:1600px)": {
                  fontSize: "0.8rem",
                  padding: "7px",
                },
              }}
            >
              Total Verified Users :{" "}
              {!isEmpty(data) ? (
                <strong>{data?.getTotalAndTickUsers?.tickUsers}</strong>
              ) : (
                0
              )}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserChartCard;
