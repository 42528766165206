import React from "react";
import { withAdminRoleRequired } from "config/auth";
import { keyRoleMap } from "config/side-menu-items";
import { Route, Routes } from "react-router-dom";
import PostCategorization from "./approval-requests";

const ModeratorSettings = () => {
  return (
    <Routes>
      <Route path="approval-requests/*" element={<PostCategorization />} />
    </Routes>
  );
};

export default withAdminRoleRequired(ModeratorSettings, {
  roles: keyRoleMap["Social Compliance"],
});
