// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBexNlfwkfWIBGUeE_cQx-md6MkHH3uZBA",
  authDomain: "sesocial-next.firebaseapp.com",
  projectId: "sesocial-next",
  storageBucket: "sesocial-next.firebasestorage.app",
  messagingSenderId: "202402775857",
  appId: "1:202402775857:web:e9926d4bbb451160026610",
};

const vapidKey =
  "BHEYFonF4OKcx7A1sKahQ2uJ12DOPHWh2XoIvm0G2zoT5c_n_mSyqDN_3MgoSwa70k4zxqZmj290accgxVwLY-A";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestNotificationPermission = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey,
    });
    if (currentToken) {
      console.log("FCM Token:", currentToken);
      return currentToken;
    } else {
      console.warn("No registration token available.");
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
