import React, { FC, ReactNode, useCallback, useEffect } from "react";
import { ApolloProvider } from "@apollo/client";

import { useAuth } from "react-oidc-context";
import { useClient } from "./client";

export const CustomApolloProvider: FC<{ children: ReactNode }> = (props) => {
  const { events, signoutSilent } = useAuth();
  const client = useClient();

  const handleAutoLogout = useCallback(async () => {
    await signoutSilent();
    window.location.href = "/";
  }, [signoutSilent]);

  useEffect(() => {
    events.addUserSignedOut(handleAutoLogout);
    return () => {
      events.removeUserSignedOut(handleAutoLogout);
    };
  }, [events, handleAutoLogout]);

  return <ApolloProvider client={client} {...props} />;
};

export default CustomApolloProvider;
