import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import {
  CircularProgress, Stack
} from "@mui/material";
import { useGetReportCountQuery } from "generated/graphql";

interface CardProps {
  title: string;
  timePeriod: any;
}

const ReportsCard: React.FC<CardProps> = ({ title, timePeriod }) => {
  const { data, loading } = useGetReportCountQuery({
    variables: {
      startDate: timePeriod.startDate,
      endDate: timePeriod.endDate,
    },
  });

  return (
    <Card sx={{
      gridColumnStart: 2,
      gridColumnEnd: 4
    }}>
      <CardContent>
        <Typography
          noWrap
          gutterBottom
          variant="h5"
          pt={2}
          pl={1}
          sx={{
            "@media (max-width:1600px)": {},
          }}
        >
          {title}
        </Typography>
        <>
          <Typography
            gutterBottom
            noWrap
            variant="h6"
            component="div"
            p={1}
            color="text.secondary"
            sx={{
              fontSize: "0.9rem",
              cursor: "pointer",
              "@media (max-width:1600px)": {},
            }}
          >
            {"Pending"}
            <span style={{ float: "right", paddingLeft: "15px" }}>
              <>
                {data?.getReportCount?.siteReportData?.pending}
                {loading ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 0.7 }}
                  >
                    <CircularProgress size={16} />
                  </Stack>
                ) : null}
              </>
            </span>
          </Typography>

          <Typography
            gutterBottom
            noWrap
            variant="h6"
            component="div"
            p={1}
            color="text.secondary"
            sx={{
              fontSize: "0.9rem",
              cursor: "pointer",
              "@media (max-width:1600px)": {},
            }}
          >
            {"Rejected"}
            <span style={{ float: "right", paddingLeft: "15px" }}>
              <>
                {data?.getReportCount?.siteReportData?.rejected}
                {loading ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 0.7 }}
                  >
                    <CircularProgress size={16} />
                  </Stack>
                ) : null}
              </>
            </span>
          </Typography>

          <Typography
            gutterBottom
            noWrap
            variant="h6"
            component="div"
            p={1}
            color="text.secondary"
            sx={{
              fontSize: "0.9rem",
              cursor: "pointer",
              "@media (max-width:1600px)": {},
            }}
          >
            {"Resolved"}
            <span style={{ float: "right", paddingLeft: "15px" }}>
              <>
                {data?.getReportCount?.siteReportData?.resolved}
                {loading ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 0.7 }}
                  >
                    <CircularProgress size={16} />
                  </Stack>
                ) : null}
              </>
            </span>
          </Typography>
        </>
      </CardContent>
    </Card>
  );
};

export default ReportsCard;
