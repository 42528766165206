import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import { withAuthenticationRequired } from "react-oidc-context";
import { withAdminAuthRequired } from "config/auth";
import RedirectToLoginPage from "components/RedirectToLoginPage";
import {
  onMessageListener,
  requestNotificationPermission,
} from "config/firebaseConfig";
import {
  DeviceType,
  useAddAdminPushNotificationTokenMutation,
} from "generated/graphql";

const drawerWidth = 240;

const MainLayout: FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const matches = useMediaQuery("(min-width:961px)");
  const [addTokenToServer] = useAddAdminPushNotificationTokenMutation();

  React.useEffect(() => {
    (async () => {
      const token = await requestNotificationPermission();
      console.log("FCM Token", token);
      await addTokenToServer({
        variables: {
          token,
          deviceType: isMobile.any() ? DeviceType.Mobileweb : DeviceType.Web,
        },
      });

      onMessageListener()
        .then((payload: any) => {
          console.log("Message received. ", payload);
          alert(payload.notification.title);
        })
        .catch((err) => console.log("Failed to receive message: ", err));
    })();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window?.document?.body;

  return (
    <Box sx={{ display: "flex" }}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <Drawer
        container={matches ? null : container}
        variant={matches ? "permanent" : "temporary"}
        open={mobileOpen || matches}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <SideMenu handleDrawerToggle={handleDrawerToggle} />
      </Drawer>
      <Box
        component="main"
        sx={
          matches
            ? {
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
              }
            : { width: { xs: "100%" }, ml: { sm: 0 } }
        }
      >
        <Toolbar variant="dense" />
        <Outlet />
      </Box>
    </Box>
  );
};

export default withAuthenticationRequired(withAdminAuthRequired(MainLayout), {
  OnRedirecting: () => <RedirectToLoginPage />,
});

export const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  Safari() {
    return navigator.userAgent.match(/Safari/i);
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows() ||
      isMobile.Safari()
    );
  },
};
