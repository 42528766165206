import { styled } from "@mui/material";
import React, { FC, Fragment, lazy, Suspense } from "react";
import PendingJobsCard from "./components/PendingJobsCard";
import NPSScoreChart from "./components/NPSScoreChart";
import { checkAccessibility } from "constants/permission";
import ReportsCard from "./components/ReportsCard";
import UserChartCard from "./components/UserChartCard";
import dayjs from "dayjs";
import MembershipLevelCard from "./components/MembershipLevelCard";
import NPSOverChart from "./components/NPSOverChart";
import { useGetRoles } from "config/auth";
import { Roles } from "constants/roles";

const DailySessionChart = lazy(() => import("./components/DailySessionChart"));
const DailySignupChart = lazy(() => import("./components/DailySignupChart"));

interface TimeProps {
  timePeriod: any;
  searchParams: URLSearchParams;
}
const MainBody: FC<TimeProps> = () => {
  const roles = useGetRoles();
  const dateFormat = (date: Date | string | undefined | null) =>
    date ? dayjs(date).format("YYYY-MM-DD") : "";
  const sevenDayPrevious = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const toDay = new Date();

  const staticDates = {
    startDate: dateFormat(sevenDayPrevious),
    endDate: dateFormat(toDay),
  };

  return (
    <FlexWrapContainer>
      {checkAccessibility(roles, [Roles.SocialAdmin, Roles.SocialMIS]) && [
        <Suspense key="MembershipLevelCard" fallback={<Fragment />}>
          <MembershipLevelCard />
        </Suspense>,
        <Suspense key="DailySignupChart" fallback={<Fragment />}>
          <DailySignupChart timePeriod={staticDates} />
        </Suspense>,
        <Suspense key="DailySessionChart" fallback={<Fragment />}>
          <DailySessionChart timePeriod={staticDates} />
        </Suspense>,
      ]}

      {checkAccessibility(roles, [Roles.SocialAdmin, Roles.SocialMIS]) && [
        <NPSOverChart key="NPSOverChart" />,
        <NPSScoreChart key="NPSScoreChart" />,
      ]}

      {checkAccessibility(roles, [Roles.SocialAdmin, Roles.SocialMarketing]) && [
        <UserChartCard
          key="UserChartCard"
          title="Verified Users"
        />,
      ]}

      {checkAccessibility(roles, [
        Roles.SocialAdmin,
        Roles.SocialModerator,
      ]) && [
        <ReportsCard
          key="ReportsCard"
          title="Reports"
          timePeriod={staticDates}
        />,
      ]}

      {checkAccessibility(roles, [
        Roles.SocialAdmin,
        Roles.SocialTechnicalOperator,
      ]) && [
        <PendingJobsCard
          key="PendingJobsCard"
          title="Pending Jobs"
          timePeriod={staticDates}
        />,
      ]}
    </FlexWrapContainer>
  );
};

export default MainBody;

const FlexWrapContainer = styled("div")({
  padding: "12px",
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridTemplateRows: "1fr",
  gap: "12px"
});
