import { IconButton, ClickAwayListener, styled, Badge } from "@mui/material";
import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Notifications from "./Notifications";
import { useAuth } from "react-oidc-context";
import { useAdminNotificationCountSubscription } from "generated/graphql";

const NotificationButton = () => {
  const { user } = useAuth();
  const { data } = useAdminNotificationCountSubscription({
    variables: {
      userId: user?.profile?.sub as string,
    },
    skip: !user?.profile?.sub,
  });
  const [openNotificationPopup, setOpenNotificationPopup] =
    React.useState<boolean>(false);

  const handleToggleNotificationPopup = () => {
    setOpenNotificationPopup((x) => !x);
  };

  const handleCloseNotificationPopup = () => {
    setOpenNotificationPopup(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseNotificationPopup}>
      <RelativeContainer>
        <IconButton onClick={handleToggleNotificationPopup}>
          <Badge badgeContent={data?.adminNotificationCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {openNotificationPopup && (
          <Notifications
            handleCloseNotificationPopup={handleCloseNotificationPopup}
          />
        )}
      </RelativeContainer>
    </ClickAwayListener>
  );
};

export default NotificationButton;

const RelativeContainer = styled("div")({
  position: "relative",
});
