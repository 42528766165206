import React, { Suspense, lazy } from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import MainLayout from "layouts/main";
import AuthCallback from "pages/AuthCallback";

const NoPageFound = lazy(() => import("pages/NoPageFound"));

import Home from "pages/home";
import LogoutOIDC from "pages/LogoutOIDC";
import ModeratorSettings from "pages/moderator-settings";
import MISSettings from "pages/mis";
import MarketingSettings from "pages/marketing-settings";
import SocialCompliance from "pages/social-compliance";
import DeveloperSettings from "pages/developer-settings";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route element={<MainLayout />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<></>}>
              <Home />
            </Suspense>
          }
        />
        <Route path="/social-compliance/*" element={<SocialCompliance />} />
        <Route path="/developer-settings/*" element={<DeveloperSettings />} />
        <Route path="/moderator-settings/*" element={<ModeratorSettings />} />
        <Route path="/mis/*" element={<MISSettings />} />
        <Route path="/marketing-settings/*" element={<MarketingSettings />} />
      </Route>
      <Route path="locallogout" element={<LogoutOIDC />} />
      <Route path="auth">
        <Route path="callback" element={<AuthCallback />} />
      </Route>
      <Route
        path="*"
        element={
          <Suspense>
            <NoPageFound />
          </Suspense>
        }
      />
    </ReactRoutes>
  );
};

export default Routes;
