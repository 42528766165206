import React from "react";
import { Route, Routes } from "react-router-dom";

const PostCategorizationList = React.lazy(
  () => import("./PostCategorisationList")
);

const PostCategorizationById = React.lazy(
  () => import("./PostCategorisationById")
);

const PostCategorization: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <React.Suspense>
            <PostCategorizationList />
          </React.Suspense>
        }
      />
      <Route
        path=":approvalRequestId"
        element={
          <React.Suspense>
            <PostCategorizationById />
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default PostCategorization;
