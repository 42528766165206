import { useMemo } from "react";
import { ApolloClient, createHttpLink, from, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import cache from "config/cache";

import { getOIDCUser } from "./auth";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

export const useClient = () => {
  return useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.GRAPHQL_API_URL,
    });

    const authLink = setContext((_, { headers }) => {
      const user = getOIDCUser();
      const token = user?.access_token;
      if (token) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        };
      } else {
        return { headers };
      }
    });

    const wsLink = new WebSocketLink({
      uri: process.env.GRAPHQL_API_URL as string,
      options: {
        reconnect: true,
        connectionParams: () => {
          const user = getOIDCUser();
          const accessToken = user?.access_token;
          if (accessToken) {
            return {
              Authorization: `Bearer ${accessToken}`,
            };
          } else {
            return {};
          }
        },
      },
    });

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      from([authLink, httpLink])
    );

    return new ApolloClient({
      link,
      cache,
    });
  }, []);
};
