import React, { FC } from "react";
import {
  AppBar,
  AppBarProps,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { drawerWidth } from "constants/index";
import { convertPathToReadable } from "utills";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProfileDropdown from "./ProfileDropdown";
import { NotificationButton } from "features/notifications";

interface HeaderProps {
  handleDrawerToggle?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const Header: FC<HeaderProps & AppBarProps> = ({
  handleDrawerToggle,
  ...props
}) => {
  const { pathname } = useLocation();
  const matches = useMediaQuery("(min-width:961px)");
  return (
    <AppBar
      position="fixed"
      sx={
        matches
          ? {
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }
          : { width: { sm: "100%" }, ml: { sm: 0 } }
      }
      {...props}
    >
      <Toolbar variant="dense">
        {handleDrawerToggle ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: matches ? "none" : "inline-flex" } }}
          >
            <Menu />
          </IconButton>
        ) : null}
        <Typography variant="h6" noWrap component="div">
          {convertPathToReadable(pathname)}
        </Typography>
        <Stack
          gap={1}
          direction="row"
          justifyContent="flex-end"
          marginLeft="auto"
        >
          <NotificationButton />
          <ProfileDropdown />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
