import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import {
  useGetAdminNotificationListQuery,
  useReadAdminNotificationByIdMutation,
} from "generated/graphql";
import { Link as ReactRouterLink } from "react-router-dom";

type NotificationsProps = {
  handleCloseNotificationPopup: () => void;
};

const Notifications: FC<NotificationsProps> = ({
  handleCloseNotificationPopup,
}) => {
  const [status, setStatus] = React.useState<"ALL" | "UNREAD" | "READ">("ALL");
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const { data, loading, refetch, fetchMore } =
    useGetAdminNotificationListQuery({
      variables: {
        limit: 10,
        offset: 0,
      },
      fetchPolicy: "network-only"
    });
  const [readNotification] = useReadAdminNotificationByIdMutation();

  const handleSetStatus = (status: "ALL" | "UNREAD" | "READ") => () => {
    setStatus(status);
    refetch({
      filter:
        status === "ALL"
          ? undefined
          : {
              read: status === "READ",
            },
      limit: 10,
      offset: 0,
    });
  };

  const handleReadNotification = (notificationId: string) => () => {
    readNotification({
      variables: {
        notificationId,
      },
    });
    handleCloseNotificationPopup();
  };

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        offset: data?.getAdminNotificationList?.length,
      },
    }).then(({ data }) => {
      if (data?.getAdminNotificationList?.length === 0) {
        setHasMore(false);
      }
    });
  };

  return (
    <AbsolutePaper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingX={1}
        paddingY={0.5}
      >
        <Typography>Notifications</Typography>
        <IconButton onClick={handleCloseNotificationPopup}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Stack direction="row" gap={1} padding={1}>
        <Button
          variant={status === "ALL" ? "contained" : "outlined"}
          sx={{ borderRadius: "12px", height: "24px" }}
          onClick={handleSetStatus("ALL")}
        >
          All
        </Button>
        <Button
          variant={status === "UNREAD" ? "contained" : "outlined"}
          sx={{ borderRadius: "12px", height: "24px" }}
          onClick={handleSetStatus("UNREAD")}
        >
          Unread
        </Button>
        <Button
          variant={status === "READ" ? "contained" : "outlined"}
          sx={{ borderRadius: "12px", height: "24px" }}
          onClick={handleSetStatus("READ")}
        >
          Read
        </Button>
      </Stack>
      <Stack
        gap={0.25}
        paddingX={1}
        paddingY={0.5}
        sx={{
          maxHeight: "768px",
          overflow: "auto",
        }}
      >
        {data?.getAdminNotificationList?.map((d) => (
          <Link
            component={ReactRouterLink}
            key={d?.notificationId}
            to={d?.link as string}
            sx={{
              textDecoration: "none",
              fontWeight: d?.read ? "normal" : "bold",
              backgroundColor: "#fafafa",
              padding: "4px 8px",
            }}
            onClick={
              d?.read
                ? handleCloseNotificationPopup
                : handleReadNotification(d?.notificationId as string)
            }
          >
            <Stack>
              <Typography sx={{ fontWeight: "inherit" }}>{d?.title}</Typography>
              <Typography variant="caption">{d?.message}</Typography>
            </Stack>
          </Link>
        ))}
        {loading ? (
          <Stack justifyContent="center" alignItems="center" padding={1.5}>
            <CircularProgress size="1.5rem" />
          </Stack>
        ) : hasMore ? (
          <Button
            size="small"
            sx={{ height: "24px", marginTop: "8px" }}
            onClick={handleFetchMore}
            variant="outlined"
          >
            See more notifications
          </Button>
        ) : null}
      </Stack>
    </AbsolutePaper>
  );
};

export default Notifications;

const AbsolutePaper = styled(Paper)({
  position: "absolute",
  right: 0,
  maxWidth: "540px",
  minWidth: "320px",
});
